<template>
  <HeaderSection>شكرا لك</HeaderSection>
  <div class="container py-5">
    <h2 class="py-5">تم استلام الطلب و سيتم الوصول فى أقل من 57 دقيقه</h2>
  </div>

  <FooterSection />
</template>
<script>

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "Finish",
  components: { HeaderSection,FooterSection }
}
</script>