<template>
  <HeaderSection>عمل حساب جديد</HeaderSection>
  <div class="container my-5">
    <form @submit.prevent="SaveUser()" class="py-5">
      <div class="mb-3">
        <label for="inputName" class="form-label">أسم المستخدم</label>
        <input
          type="text"
          class="form-control"
          id="inputName"
          v-model="userName"
        />
      </div>
      <div class="mb-3">
        <label for="inputEmail" class="form-label">البريد الإلكترونى</label>
        <input
          type="email"
          class="form-control"
          id="inputEmail"
          v-model="userEmail"
        />
      </div>
      <div class="mb-3">
        <label for="inputPassword" class="form-label">كلمه المرور</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword"
          v-model="userPassword"
        />
      </div>
      <div class="mb-3">
        <label for="inputArea" class="form-label">المنطقه</label>
        <select class="form-select" v-model="userArea" id="inputArea">
          <option disabled selected value="">حدد المطقه</option>
          <option value="shrouq">الشروق</option>
          <option value="ismailia">إسماعليه</option>
          <option value="suze">السويس</option>
          <option value="cairo">القاهرة</option>
          <option value="geza">الجيزة</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="formFile" class="form-label">الصورة الشخصيه</label>
        <input
          class="form-control"
          type="file"
          id="formFile"
          accept="image/jpeg"
          @change="DetectFiles($event.target.files)"
        />
      </div>
      <div class="mb-3">
        <label for="userAdrres" class="form-label">العنوان</label>
        <input
          type="text"
          class="form-control"
          id="userAdrres"
          v-model="userAdrres"
        />
      </div>
      <div class="mb-3">
        <label for="userPhone" class="form-label">الهاتف</label>
        <input
          type="text"
          class="form-control"
          id="userPhone"
          v-model="userPhone"
        />
      </div>
      <h4 class="mb-3">نوع الحساب</h4>
      <div class="row px-3">
        <div class="form-check col-6 mb-3">
          <input
            class="form-check-input"
            type="radio"
            name="userType"
            id="userType2"
            v-model="userType"
            value="customer"
          />
          <label class="form-check-label" for="userType2"> عميل </label>
        </div>
        <div class="form-check col-6 mb-3">
          <input
            class="form-check-input"
            type="radio"
            name="userType"
            id="userType1"
            v-model="userType"
            value="foodmaker"
          />
          <label class="form-check-label" for="userType1"> صانع طعام </label>
        </div>
      </div>
      <div v-if="userType == 'foodmaker'">
        <div class="mb-3">
          <label for="inputHours" class="form-label">عدد ساعات العمل</label>
          <input
            type="number"
            class="form-control"
            id="inputHours"
            v-model="userHours"
            min="1"
            oninput="validity.valid||(value='');"
          />
        </div>
        <div class="mb-3">
          <label for="inputHours" class="form-label">نبذه شخصيه</label>
          <textarea
            class="form-control"
            id="inputDes"
            v-model="userDes"
          ></textarea>
        </div>
      </div>

      <button
        type="submit"
        class="btn mt-5 w-100"
        style="background: #fd651b; color: #fff"
      >
        تسجيل الحساب
      </button>
    </form>
  </div>
  <FooterSection />
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "Registrar",
  components: { HeaderSection, FooterSection },
  setup() {
    const router = useRouter();
    const db = firebase.firestore();

    let userName = ref("");
    let userEmail = ref("");
    let userPassword = ref("");
    let userType = ref("customer");
    let userHours = ref("0");
    let userDes = ref("");
    let userArea = ref("");
    let userAdrres = ref("");
    let userImg = ref("");
    let userPhone = ref("");
    let userId = Math.random().toString(36).slice(-8);

    function SaveUser() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(userEmail.value, userPassword.value)
        .then(() => {
          db.collection("users")
            .add({
              Id: userId,
              Name: userName.value,
              Email: userEmail.value,
              Type: userType.value,
              Hours: userHours.value,
              Des: userDes.value,
              Area: userArea.value,
              Adrres: userAdrres.value,
              Img: userImg.value,
              Phone: userPhone.value,
            })
            .then(() => {
              router.push("/");
            });
        });
    }

    function DetectFiles(img) {
      const imgData = img[0];
      const storageRef = firebase.storage().ref(`${imgData.name}`).put(imgData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          console.log(snapshot);
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            userImg.value = downloadURL;
          });
        }
      );
    }

    return {
      userName,
      userEmail,
      userPassword,
      userType,
      userHours,
      userDes,
      userArea,
      userAdrres,
      userPhone,
      SaveUser,
      DetectFiles,
    };
  },
};
</script>
