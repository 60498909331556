<template>
  <HeaderSection>المنيو</HeaderSection>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 col-lg-4 my-4" v-for="data in menu" :key="data.Id">
        <router-link :to="'/meal/' + data.Id">
          <div class="w-100">
            <img :src="data.Img" class="w-100 menu-img" />
          </div>
          <div class="w-100 my-3">
            <h3 class="menu-title">{{ data.Name }}</h3>
          </div>
          <div class="w-100 my-3">
            <h3 class="menu-title">السعر : {{ data.Price }}</h3>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <FooterSection />
</template>

<script>
import { reactive, ref } from "vue";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";
import { useStore } from "vuex";
export default {
  name: "Menu",
  components: { HeaderSection, FooterSection },
  setup() {
    const store = useStore();
    const db = firebase.firestore();

    const mealArea = ref("");
    const menu = reactive([]);

    getData();
    console.log(store.state.userEmail);
    function getData() {
      menu.splice(0, menu.length);
      db.collection("meals")
        .where("UserEmail", "==", store.state.userEmail)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            menu.push(doc.data());
          });
        });
    }

    return { mealArea, menu, getData };
  },
};
</script>

<style lang="scss" scoped>
.username {
  font-size: 50px;
}
.menu-img {
  height: 350px;
}
.menu-title,
a {
  text-align: center;
  text-decoration: none;
  color: #fd651b;
}
</style>
