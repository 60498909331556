export default function (next, store, middleware) {
  if (middleware == "notLogin") {
    store.state.isLogin ? next("/") : next();
  } else if (middleware == "isLogin") {
    !store.state.isLogin ? next("/") : next();
  } else if (middleware == "foodmaker") {
    !store.state.userType == "foodmaker" ? next("/") : next();
  } else if (middleware === "customer") {
    !store.state.userType == "customer" ? next("/") : next();
  }
}
