<template>
  <HeaderSection>البروفيل</HeaderSection>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 col-lg-4">
        <img :src="UserImg" class="w-100 rounded" />
      </div>
      <div class="col-12 col-lg-8">
        <h2 class="text-center my-3 username">{{ UserName }}</h2>
        <h4 class="my-3">البريد الإلكترونى :- {{ UserEmail }}</h4>
        <h4 class="my-3" v-if="UserType == 'foodmaker'">
          نوع الحساب : صانع طعام
        </h4>
        <h4 class="my-3" v-if="UserType == 'customer'">نوع الحساب : عميل</h4>
        <h4 class="my-3" v-if="UserType == 'foodmaker'">
          عدد ساعات العمل :- {{ UserHours }}
        </h4>
        <h4 class="my-3" v-if="UserType == 'foodmaker'">
          العنوان :- {{ UserAdrres }}
        </h4>
        <h4 class="my-3" v-if="UserType == 'foodmaker'">
          المنطقه :- {{ UserArea }}
        </h4>
        <hr />
        <p>{{ UserDes }}</p>
        <hr />
      </div>
    </div>
  </div>
  <FooterSection />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";
export default {
  name: "Profile",
  components: { HeaderSection, FooterSection },
  setup() {
    const store = useStore();
    const UserName = computed(() => store.state.userName);
    const UserEmail = computed(() => store.state.userEmail);
    const UserImg = computed(() => store.state.userImg);
    const UserDes = computed(() => store.state.userDes);
    const UserHours = computed(() => store.state.userHours);
    const UserAdrres = computed(() => store.state.userAdress);
    const UserArea = computed(() => store.state.userArea);
    const UserType = computed(() => store.state.userType);
    return {
      UserName,
      UserImg,
      UserEmail,
      UserDes,
      UserAdrres,
      UserArea,
      UserHours,
      UserType,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../assets/header.jpg");
  width: 100%;
  height: 350px;
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: cover;
  position: relative;
}
.overlay {
  width: 100%;
  height: 100%;
  background-color: #5c636a;
  opacity: 0.9;
  z-index: 5;
  position: absolute;
}
.contant {
  position: absolute;
  z-index: 6;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  h1 {
    width: 100%;
    text-align: center;
    font-size: 5rem;
    color: #fd651b;
  }
}
.username {
  font-size: 50px;
}
</style>