/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Registrar from "@/views/Registrar.vue";
import Login from "@/views/Login.vue";
import Profile from "@/views/Profile.vue";
import AddMenu from "@/views/AddMenu.vue";
import Menu from "@/views/Menu.vue";
import Meal from "@/views/Meal.vue";
import Cart from "@/views/Cart.vue";
import Order from "@/views/Order.vue";
import Finish from "@/views/Finish.vue";
import MyMenu from "@/views/MyMenu.vue";
import store from "@/store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    meta: { middleware: "foodmaker" },
  },
  {
    path: "/registrar",
    name: "Registrar",
    component: Registrar,
    meta: { middleware: "notLogin" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { middleware: "notLogin" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { middleware: "isLogin" },
  },
  {
    path: "/addmenu",
    name: "AddMenu",
    component: AddMenu,
    meta: { middleware: "foodmaker" },
  },
  {
    path: "/menu/:section?",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/meal/:meal",
    name: "Meal",
    component: Meal,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: { middleware: "customer" },
  },
  {
    path: "/finish",
    name: "Finish",
    component: Finish,
    meta: { middleware: "customer" },
  },
  {
    path: "/mymenu",
    name: "MyMenu",
    component: MyMenu,
    meta: { middleware: "foodmaker" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, form, next) => {
  if (to.meta.middleware) {
    const middleware = require(`@/middleware/index`);
    if (middleware) {
      middleware.default(next, store, to.meta.middleware);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
