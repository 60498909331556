<template>
<HeaderSection>تسجيل الدخول</HeaderSection>
  <div class="container my-5">
    <form @submit.prevent="submit" class="py-5">
      <div class="mb-3">
        <label for="inputEmail" class="form-label">البريد الإلكترونى</label>
        <input
          type="email"
          class="form-control"
          id="InputEmail"
          v-model="email"
        />
      </div>
      <div class="mb-3">
        <label for="inputPassword" class="form-label">كلمه المرور</label>
        <input
          type="password"
          class="form-control"
          id="inputPassword"
          v-model="password"
        />
      </div>
      <button
        type="submit"
        class="btn mt-5 w-100"
        style="background: #fd651b; color: #fff"
      >
        تسجيل الدخول
      </button>
    </form>
  </div>
  <FooterSection />
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "Login",
  components: { HeaderSection,FooterSection },
  setup() {
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");

    function submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then((userCredential) => {
          const db = firebase.firestore();
          const user = userCredential.user;
          
          db.collection("users")
            .where("Email", "==", user.email)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                store.commit("authLogin", doc.data());
              });
            });
          router.push("/");
        });
    }

    return { email, password, submit };
  },
};
</script>
