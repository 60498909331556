import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      isLogin: false,
      userId: "",
      userName: "",
      userEmail: "",
      userDes: "",
      userHours: "",
      userType: "",
      userArea: "",
      userAdress: "",
      userPhone: "",
      userImg: "",
      cartItem: [],
    };
  },
  mutations: {
    authLogin(state, payload) {
      state.isLogin = true;
      state.userId = payload.Id;
      state.userName = payload.Name;
      state.userEmail = payload.Email;
      state.userDes = payload.Des;
      state.userHours = payload.Hours;
      state.userType = payload.Type;
      state.userArea = payload.Area;
      state.userAdress = payload.Adrres;
      state.userPhone = payload.Phone;
      state.userImg = payload.Img;
    },
    authLogout(state) {
      state.isLogin = false;
      state.userId = "";
      state.userName = "";
      state.userEmail = "";
      state.userDes = "";
      state.userHours = "";
      state.userType = "";
      state.userArea = "";
      state.userAdress = "";
      state.userPhone = "";
      state.userImg = "";
    },
    addToCart(state, payload) {
      let endFun = true;
      for (let index = 0; index < state.cartItem.length; index++) {
        if (state.cartItem[index].MealName === payload.MealName) {
          state.cartItem[index].MealQ =
            parseInt(state.cartItem[index].MealQ) + parseInt(payload.MealQ);
          endFun = false;
        }
      }
      if (endFun) {
        state.cartItem.push(payload);
      }
    },
    removeFromCart(state, payload) {
      for (let index = 0; index < state.cartItem.length; index++) {
        if (state.cartItem[index].MealName === payload) {
          state.cartItem.splice(index, 1);
        }
      }
    },
    emptyCart(state) {
      state.cartItem.splice(0, state.cartItem.length);
    },
  },
  actions: {},
  modules: {},
});
