<template>
  <HomeContnet />
</template>

<script>
import HomeContnet from "@/components/home/HomeContnet";
export default {
  name: "home",
  components: { HomeContnet },
};
</script>
