<template>
<HeaderSection>وجبه</HeaderSection>
  <div class="container my-5">
    <div class="row">
      <div class="col-12 col-md-4">
        <h4 class="text-center">{{ UserName }}</h4>
        <hr />
        <img :src="UserImg" class="w-100 mb-3" height="450" />
        <p class="user-des">{{ UserDes }}</p>
      </div>
      <div class="col-12 col-md-8">
        <h2 class="mb-5 text-center">{{ MealName }}</h2>
        <img :src="MealImg" class="w-100 mb-3" height="600" />
        <p>{{ MealDes }}</p>
        <h3>السعر {{ MealPrice }}</h3>
        <div class="row" v-if="UserType == 'customer'">
          <div class="col-6">
            <label for="MealQ">الكميه</label>
            <input
              type="number"
              v-model="MealQ"
              id="MealQ"
              class="mx-3"
              min="1"
              oninput="validity.valid||(value='');"
            />
          </div>

          <div class="col-6">
            <button
              type="button"
              class="btn btn-secondary"
              v-if="UserType == 'customer'"
              @click="
                AddCart(UserId, UserName, MealId, MealName, MealPrice, MealQ)
              "
            >
              أضف للعربه
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterSection />
</template>

<script>
import { onBeforeMount, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "Meal",
  components: { HeaderSection,FooterSection },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const db = firebase.firestore();

    const MealId = ref("");
    const MealName = ref("");
    const MealImg = ref("");
    const MealPrice = ref("");
    const MealDes = ref("");
    const UserName = ref("");
    const UserImg = ref("");
    const UserDes = ref("");
    const UserType = computed(() => store.state.userType);
    const UserId = ref("");
    const MealQ = ref(1);
    onBeforeMount(() => {
      db.collection("meals")
        .where("Id", "==", route.params.meal)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((mealDoc) => {
            MealId.value = mealDoc.data().Id;
            MealImg.value = mealDoc.data().Img;
            MealName.value = mealDoc.data().Name;
            MealDes.value = mealDoc.data().Des;
            MealPrice.value = mealDoc.data().Price;
            db.collection("users")
              .where("Email", "==", mealDoc.data().UserEmail)
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  UserId.value = doc.data().Id;
                  UserImg.value = doc.data().Img;
                  UserName.value = doc.data().Name;
                  UserDes.value = doc.data().Des;
                });
              });
          });
        });
    });

    function AddCart(ChefId, ChefName, MealId, MealName, MealPrice, MealQ) {
      store.commit("addToCart", {
        ChefId,
        ChefName,
        MealId,
        MealName,
        MealPrice,
        MealQ,
      });
      router.push("/Cart");
    }

    return {
      MealId,
      MealName,
      MealImg,
      MealDes,
      MealPrice,
      UserName,
      UserImg,
      UserDes,
      UserType,
      UserId,
      MealQ,
      AddCart,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-des {
  text-align: justify;
}
</style>