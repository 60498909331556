<template>
  <HeaderSection>المنيو</HeaderSection>
  <div class="container my-5">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="inputArea" class="form-label">المنطقه</label>
          <select class="form-select" v-model="mealArea" @change="getData()">
            <option disabled selected value="">حدد المطقه</option>
            <option value="shrouq">الشروق</option>
            <option value="ismailia">إسماعليه</option>
            <option value="suze">السويس</option>
            <option value="cairo">القاهرة</option>
            <option value="geza">الجيزة</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-lg-4 my-4" v-for="data in menu" :key="data.Id">
        <router-link :to="'/meal/' + data.Id">
          <div class="w-100">
            <img :src="data.Img" class="w-100 menu-img" />
          </div>
          <div class="w-100 my-3">
            <h3 class="menu-title">{{ data.Name }}</h3>
          </div>
          <div class="w-100 my-3">
            <h3 class="menu-title">السعر : {{ data.Price }}</h3>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <FooterSection />
</template>

<script>
import { reactive, ref } from "vue";
import { useRoute } from "vue-router";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";
export default {
  name: "Menu",
  components: { HeaderSection, FooterSection },
  setup() {
    const route = useRoute();
    const db = firebase.firestore();

    const mealArea = ref("");
    const menu = reactive([]);

    getData();
    function getData() {
      menu.splice(0, menu.length);
      db.collection("meals")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (route.params.section.length > 0) {
              if (mealArea.value == "") {
                if (route.params.section == doc.data().Section) {
                  menu.push(doc.data());
                }
              } else {
                if (mealArea.value == doc.data().Area) {
                  if (route.params.section == doc.data().Section) {
                    menu.push(doc.data());
                  }
                }
              }
            } else {
              if (mealArea.value == "") {
                menu.push(doc.data());
              } else {
                if (mealArea.value == doc.data().Area) {
                  menu.push(doc.data());
                }
              }
            }
          });
        });
    }

    return { mealArea, menu, getData };
  },
};
</script>

<style lang="scss" scoped>
.username {
  font-size: 50px;
}
.menu-img {
  height: 350px;
}
.menu-title,
a {
  text-align: center;
  text-decoration: none;
  color: #fd651b;
}
</style>