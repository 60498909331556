<template>
  <div class="container-fluid">
    <div class="row">
      <router-link class="col-12 col-lg-6 item sea-food" to="/menu/sea">
        <div class="item">
          <div class="overlay">
            <div class="contnet">
              <h2>مأكولات بحريه</h2>
              <p>
                المأكولات البحريه , الأسماك , جمبرى و المأكولات البحريه الأخرى
              </p>
            </div>
          </div>
        </div>
      </router-link>
      <router-link class="col-12 col-lg-3 item drinks" to="/menu/drink">
        <div class="item">
          <div class="overlay">
            <div class="contnet">
              <h2>مشروبات</h2>
              <p>قسم المشروبات الساخنه و الكوكتيلات و المشروبات البادره</p>
            </div>
          </div>
        </div>
      </router-link>
      <router-link class="col-12 col-lg-3 pasta item" to="/menu/pasta">
        <div class="item">
          <div class="overlay">
            <div class="contnet">
              <h2>الباستا و المكرونه</h2>
              <p>قسم مكرونه و الباستا و المعجنات و مشتقتهم</p>
            </div>
          </div>
        </div>
      </router-link>

      <div class="col-12 col-lg-9">
        <div class="row w-100 p-0 m-0">
          <router-link class="col-12 col-lg-6 item grill" to="/menu/grill">
            <div class="item">
              <div class="overlay">
                <div class="contnet">
                  <h2>مشويات</h2>
                  <p>فراخ مشويه , كباب و كفته , شيش .... إلخ</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link class="col-12 col-lg-6 sweet item" to="/menu/sweet">
            <div class="item">
              <div class="overlay">
                <div class="contnet">
                  <h2>حلويات</h2>
                  <p>حلويات شرقيه و حلويات غربيه</p>
                </div>
              </div>
            </div>
          </router-link>
          <router-link class="col-12 item fastfood" to="/menu/fast">
            <div class="item">
              <div class="overlay">
                <div class="contnet">
                  <h2>وجبات سريعه</h2>
                  <p>بيتزا , برجر , كريب .... الخ</p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <router-link class="col-12 col-lg-3 item beaked" to="/menu/bakery">
        <div class="item" style="height: 900px">
          <div class="overlay">
            <div class="contnet">
              <h2>مخبوزات</h2>
              <p>مخبوزات و مشتقات المخبوازت</p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.col-12 {
  padding: 0;
  background-size: cover;
  .item {
    height: 450px;
    overflow: hidden;
    position: relative;
    .overlay {
      width: 100%;
      height: 0;
      background: #f8f9fa99;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      opacity: 0.4;
      transition: all 1s;
      .contnet {
        width: 100%;
        align-self: center;
        text-align: center;
        color: #000000;
        h2 {
          font-size: 2rem;
          color: #fd651b;
        }
        p {
          padding: 15px 0;
          font-size: 18px;
        }
      }
    }
    &:hover {
      .overlay {
        height: 100%;
        opacity: 1;
      }
    }
  }
}

.sea-food {
  background-image: url("../../assets/seafood.jpg");
}
.drinks {
  background-image: url("../../assets/drinks.jpg");
}
.pasta {
  background-image: url("../../assets/pasta.jpg");
}
.grill {
  background-image: url("../../assets/grill.jpg");
}
.sweet {
  background-image: url("../../assets/sweet.jpg");
}
.fastfood {
  background-image: url("../../assets/fastfood.jpg");
}
.beaked {
  background-image: url("../../assets/beaked.jpg");
}
</style>