<template>
  <Navbar />
  <router-view />
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import firebase from "@/firebase";

import Navbar from "@/components/layouts/Navbar";
export default {
  components: { Navbar },
  name: "App",
  setup() {
    const store = useStore();

    const CartItems = computed(() => store.state.CartItem);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const db = firebase.firestore();
        db.collection("users")
          .where("Email", "==", user.email)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              store.commit("authLogin", doc.data());
            });
          });
      } else {
        store.commit("authLogout");
      }
    });
    return {
      CartItems,
    };
  },
};
</script>
<style lang="scss">
* {
  font-family: "Almarai", sans-serif;
}
</style>
