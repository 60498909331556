<template>
  <header class="container-fluid p-2">
    <div class="row w-100">
      <div class="col-4">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/logo.png" width="150" />
        </router-link>
      </div>
      <div class="col-4 d-lg-flex justify-content-center mt-2">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link
                    to="/"
                    class="nav-link active"
                    aria-current="page"
                    >الرئيسيه</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link to="/menu" class="nav-link" href="#"
                    >المنيو</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div class="col-4 justify-content-center h-100 card-login text-end pe-5">
        <div class="dropdown d-inline-block mx-4">
          <button
            class="btn btn-secondary"
            type="button"
            id="LoginBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fas fa-user-alt"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="LoginBtn">
            <li>
              <router-link
                to="/login"
                class="dropdown-item"
                v-if="IsLogin == false"
                >تسجيل دخول
              </router-link>
            </li>
            <li>
              <router-link
                class="dropdown-item"
                to="/registrar"
                v-if="IsLogin == false"
                >حساب جديد</router-link
              >
            </li>
            <li>
              <router-link
                to="/profile"
                class="dropdown-item"
                v-if="IsLogin == true"
                >الصفحه الشخصيه
              </router-link>
            </li>
            <li>
              <router-link
                to="/mymenu"
                class="dropdown-item"
                v-if="$store.state.userType == 'foodmaker'"
                >منيو مطعمى
              </router-link>
            </li>
            <li>
              <router-link
                to="/addmenu"
                class="dropdown-item"
                v-if="$store.state.userType == 'foodmaker'"
                >أضف الوجبه
              </router-link>
            </li>
            <li>
              <router-link
                to="/order"
                class="dropdown-item"
                v-if="$store.state.userType == 'foodmaker'"
                >المطلوب
              </router-link>
            </li>
            <li>
              <div @click="logout" class="dropdown-item" v-if="IsLogin == true">
                تسجيل خروج
              </div>
            </li>
          </ul>
        </div>

        <router-link
          class="btn btn-secondary d-inline-block"
          to="/cart"
          v-if="UserType == 'customer'"
        >
          <i class="fas fa-shopping-cart"></i>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import firebase from "@/firebase";

export default {
  name: "Navbar",
  setup() {
    const router = useRouter();
    const store = useStore();

    const IsLogin = computed(() => store.state.isLogin);
    const UserType = computed(() => store.state.userType);
    function logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          router.push("/");
        });
    }

    return { IsLogin, UserType, logout };
  },
};
</script>

<style scoped>
header {
  background-color: #eee !important;
}
.card-login {
  padding: 13px 0;
}
</style>
