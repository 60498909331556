<template>
  <footer>
    <img src="@/assets/logo.png" width="200" class="my-5" />
  </footer>
</template>
<script>
export default {
  name: "FooterSection",
};
</script>
<style>
footer {
  background-color: #eeeeee;
  text-align: center;
}
</style>