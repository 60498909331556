<template>
  <HeaderSection>أضف وجبه</HeaderSection>
  <div class="container my-5">
    <form @submit.prevent="SaveMeal()" class="py-5">
      <div class="mb-3">
        <label for="inputName" class="form-label">أسم الوجبه</label>
        <input
          type="text"
          class="form-control"
          id="InputName"
          v-model="mealName"
        />
      </div>
      <div class="mb-3">
        <label for="formFile" class="form-label">الصورة</label>
        <input
          class="form-control"
          type="file"
          id="formFile"
          accept="image/jpeg"
          @change="DetectFiles($event.target.files)"
        />
      </div>
      <div class="mb-3">
        <label for="inputArea" class="form-label">القسم</label>
        <select class="form-select" v-model="mealSection">
          <option disabled selected value="">حدد القسم</option>
          <option value="sea">مأكولات بحريه</option>
          <option value="drink">مشروبات</option>
          <option value="pasta">الباستا و المكرونه</option>
          <option value="grill">مشويات</option>
          <option value="sweet">حلويات</option>
          <option value="bakery">مخبوزات</option>
          <option value="fast">وجبات سريعه</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="inputHours" class="form-label">وصف الوجبه</label>
        <textarea
          class="form-control"
          id="inputDes"
          v-model="MealDes"
        ></textarea>
      </div>
      <div class="mb-3">
        <label for="mealPrice" class="form-label">السعر</label>
        <input
          class="form-control"
          type="Number"
          id="mealPrice"
          v-model="mealPrice"
          min="1"
          oninput="validity.valid||(value='');"
        />
      </div>
      <button
        type="submit"
        class="btn mt-5 w-100"
        style="background: #fd651b; color: #fff"
      >
        تسجيل الوجبه
      </button>
    </form>
  </div>
  <FooterSection />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import firebase from "@/firebase";
import "firebase/storage";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "AddMenu",
  components: { HeaderSection, FooterSection },
  setup() {
    const db = firebase.firestore();
    const router = useRouter();
    const store = useStore();

    const mealName = ref("");
    const mealPrice = ref("");
    const mealSection = ref("");
    const MealDes = ref("");
    const UserImg = ref("");
    const UserArea = computed(() => store.state.userArea);
    const UserEmail = computed(() => store.state.userEmail);

    function SaveMeal() {
      db.collection("meals")
        .add({
          Id: Math.random().toString(36).slice(-8),
          Name: mealName.value,
          Section: mealSection.value,
          Des: MealDes.value,
          Area: UserArea.value,
          Img: UserImg.value,
          UserEmail: UserEmail.value,
          Price: mealPrice.value,
        })
        .then(() => {
          router.push("/mymenu");
        });
    }

    function DetectFiles(img) {
      const imgData = img[0];
      const storageRef = firebase.storage().ref(`${imgData.name}`).put(imgData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          console.log(snapshot);
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            UserImg.value = downloadURL;
          });
        }
      );
    }

    return {
      mealName,
      mealSection,
      MealDes,
      mealPrice,
      SaveMeal,
      DetectFiles,
    };
  },
};
</script>
