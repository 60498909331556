<template>
  <HeaderSection>عربه التسوق</HeaderSection>
  <div class="container my-5" v-if="totalCalc > 0">
    <div class="row">
      <div class="col-6">أسم الوجبه</div>
      <div class="col-2">السعر</div>
      <div class="col-2">الكميه</div>
      <div class="col-2">إلغاء</div>
    </div>
    <hr />
    <div class="row my-4" v-for="data in CartItems" :key="data.index">
      <div class="col-6">{{ data.MealName }}</div>
      <div class="col-2">{{ data.MealPrice }}</div>
      <div class="col-2">{{ data.MealQ }}</div>
      <div class="col-2">
        <button
          type="button"
          class="btn btn-secondary"
          @click="RemoveCart(data.MealName)"
        >
          حذف
        </button>
      </div>
    </div>
    <hr />
    <div class="row mt-4">
      <h2 class="col-6">الإجمالى : {{ totalCalc }}</h2>
    </div>
    <hr />
    <h4 class="mb-3">طريقه الدفع</h4>
    <div class="row px-3">
      <div class="form-check col-6 mb-3">
        <input
          class="form-check-input"
          type="radio"
          name="payType"
          id="payType2"
          v-model="payType"
          value="cash"
        />
        <label class="form-check-label" for="payType2"> كاش </label>
      </div>
      <div class="form-check col-6 mb-3">
        <input
          class="form-check-input"
          type="radio"
          name="payType"
          id="payType1"
          v-model="payType"
          value="creadt"
        />
        <label class="form-check-label" for="userType1"> كريدت كارت </label>
      </div>
      <div class="col-12 mb-3" v-if="payType === 'creadt'">
        <div>
          <div class="padding">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Credit Card</strong>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="name">الأسم</label>
                          <input
                            class="form-control"
                            id="name"
                            type="text"
                            placeholder="ادخل الأسم الموجود على البطاقه"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="ccnumber">رقم البطاقه</label>
                          <div class="input-group">
                            <input
                              class="form-control"
                              type="text"
                              placeholder="0000 0000 0000 0000"
                              autocomplete="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-4">
                        <label for="ccmonth">الشهر</label>
                        <select class="form-control" id="ccmonth">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="ccyear">السنه</label>
                        <select class="form-control" id="ccyear">
                          <option>2021</option>
                          <option>2022</option>
                          <option>2023</option>
                          <option>2024</option>
                          <option>2025</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label for="cvv">CVV/CVC</label>
                          <input
                            class="form-control"
                            id="cvv"
                            type="text"
                            placeholder="123"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="btn btn-secondary" @click="SaveOrder()">
        دفع
      </button>
    </div>
  </div>

  <FooterSection />
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";

export default {
  name: "Cart",
  components: { HeaderSection, FooterSection },
  setup() {
    const router = useRouter();
    const db = firebase.firestore();
    const store = useStore();

    const CartItems = computed(() => store.state.cartItem);
    const payType = ref("cash");
    const OrderId = Math.random().toString(36).slice(-8);
    const OrderTime = firebase.firestore.Timestamp.fromDate(new Date());
    const UserId = computed(() => store.state.userId);
    const UserName = computed(() => store.state.userName);
    const UserAddress = computed(() => store.state.userAdress);
    const UserPhone = computed(() => store.state.userPhone);
    const totalCalc = computed(() => {
      let total = 0;
      let cart = store.state.cartItem;
      cart.forEach((element) => {
        total = total + element.MealQ * parseInt(element.MealPrice);
      });
      return total;
    });

    function SaveOrder() {
      CartItems.value.forEach((element) => {
        db.collection("orders")
          .add({
            OrderId: OrderId,
            OrderTime: OrderTime,
            UserId: UserId.value,
            UserName: UserName.value,
            UserAddress: UserAddress.value,
            UserPhone: UserPhone.value,
            MealId: element.MealId,
            MealName: element.MealName,
            MealQ: element.MealQ,
            ChefId: element.ChefId,
          })
          .then(() => {
            router.push("/finish");
            store.commit("emptyCart");
          });
      });
    }
    function RemoveCart(MealName) {
      store.commit("removeFromCart", MealName);
    }
    return { CartItems, payType, SaveOrder, RemoveCart, totalCalc };
  },
};
</script>