<template>
  <div class="header">
    <div class="overlay"></div>
    <div class="contant">
      <h1><slot></slot></h1>
    </div>
  </div>
</template>

<script>
export default {
  name:"HeaderSection"
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/header.jpg");
  width: 100%;
  height: 250px;
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: cover;
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: #5c636a;
    opacity: 0.9;
    z-index: 5;
    position: absolute;
  }
  .contant {
    position: absolute;
    z-index: 6;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    h1 {
      width: 100%;
      text-align: center;
      font-size: 5rem;
      color: #fd651b;
    }
  }
}
</style>