<template>
  <HeaderSection>الطلبات</HeaderSection>
  <div class="container-fluid text-center my-5">
    <div class="row">
      <div class="col">رقم الطلب</div>
      <div class="col">وقت الطلب</div>
      <div class="col">تاريخ الطلب</div>
      <div class="col">أسم الوجيه</div>
      <div class="col">الكميه المطلوبه</div>
      <div class="col">أسم الطالب</div>
      <div class="col">هاتف الطالب</div>
      <div class="col">العنوان</div>
    </div>
    <hr />
    <div class="row" v-for="(Order, index) in Orders" :key="index">
      <div class="col">{{ Order.OrderId }}</div>
      <div class="col">
        {{ Order.OrderTime.toDate().toLocaleTimeString("en-US") }}
      </div>
      <div class="col">{{ Order.OrderTime.toDate().toDateString() }}</div>
      <div class="col">{{ Order.MealName }}</div>
      <div class="col">{{ Order.MealQ }}</div>
      <div class="col">{{ Order.UserName }}</div>
      <div class="col">{{ Order.UserPhone }}</div>
      <div class="col">{{ Order.UserAddress }}</div>
      <hr />
    </div>
  </div>
  <FooterSection />
</template>

<script>
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import firebase from "@/firebase";

import HeaderSection from "@/components/layouts/HeaderSection";
import FooterSection from "@/components/layouts/FooterSection";
export default {
  name: "Order",
  components: { HeaderSection, FooterSection },
  setup() {
    const store = useStore();
    const db = firebase.firestore();
    const Orders = reactive([]);
    const UserId = computed(() => store.state.userId);
    Orders.splice(0, Orders.length);
    db.collection("orders")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().ChefId == UserId.value) {
            console.log(Orders);
            Orders.push(doc.data());
          }
        });
      });

    return { Orders };
  },
};
</script>
